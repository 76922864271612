<template>
  <div class="events-filter">
    <button type="button" class="events-filter-toggler" :class="isActive && '-active'" @click="isActive = !isActive">
      {{currentFilter.title}}
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.99 6.91">
        <path d="M.71.71,5.5,5.5,10.29.71" fill="none" stroke="currentColor" stroke-width="2"/>
      </svg>
    </button>
    <transition name="events-filter">
      <div class="events-filter-dropdown" v-if="filters && baseUrl && isActive">
        <a :href="`${baseUrl}${item.slug && item.slug + '/'}${urlSuffix}`" class="filter" v-for="item in filters" :key="item.slug">{{item.title}}</a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    baseUrl: {
      type: String,
      required: true
    },
    filters: {
			type: Array,
			required: true
    },
    current: {
      type: String,
      default: null
    },
    urlSuffix: {
      type: String
    }
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    currentFilter() {
      return this.filters.find(item => item.slug === this.current);
    }
  }
}
</script>
