import { tns } from "tiny-slider/src/tiny-slider.module"

var eventsSlider = document.querySelector('.js-events-slider');

if (eventsSlider !== null) {
  var slider = tns({
    container: eventsSlider,
    items: 1,
    controls: true,
    controlsPosition: 'bottom',
    controlsText: [
      '<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="5.466" height="9.519" viewBox="0 0 5.466 9.519"><path d="M-5465.843-14157.5l-4.406,4.405,4.406,4.406" transform="translate(5470.956 14157.853)" fill="none" stroke-width="1"/></svg>',
      '<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="5.466" height="9.519" viewBox="0 0 5.466 9.519"><path d="M-5465.843-14157.5l-4.406,4.405,4.406,4.406" transform="translate(-5465.489 -14148.334) rotate(180)" fill="none" stroke-width="1"/></svg>'
    ],
    nav: false,
    loop: false,
    gutter: 36,
    responsive: {
      768: {
        items: 2
      },
      1241: {
        items: 3
      }
    }
  });
}

document.addEventListener('click', (e)=> {
  if (e.target.matches('.tns-item:not(.tns-slide-active)')) {
    let targetIndex = e.target.id.charAt(e.target.id.length -1)
    let currentIndex = slider.getInfo().displayIndex
    let items = slider.getInfo().items
    if (targetIndex > currentIndex) {
      slider.goTo(targetIndex - items + 1);
    } else {
      slider.goTo(targetIndex);
    }
  }
})

