var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calendar-add" }, [
    _c(
      "button",
      {
        staticClass: "dropdown-toggler",
        on: {
          click: function($event) {
            _vm.isActive = !_vm.isActive
          }
        }
      },
      [
        _c(
          "svg",
          {
            staticClass: "icon",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 22.61 23.73"
            }
          },
          [
            _c("g", [
              _c("path", {
                attrs: {
                  d:
                    "M4,0V1.12H0V23.73H22.61V1.12h-4V0H17.38V1.12H5.23V0ZM1.22,2.33H4V3.45H5.23V2.33H17.38V3.45H18.6V2.33h2.8V5.61H1.21Zm0,4.49H21.4v15.7H1.21Zm6.72,3.27v1.22h2.25V10.09Zm4.49,0v1.22h2.24V10.09Zm4.49,0v1.22h2.24V10.09ZM3.45,14.58v1.21H5.7V14.58Zm4.49,0v1.21h2.24V14.58Zm4.49,0v1.21h2.24V14.58Zm4.48,0v1.21h2.25V14.58ZM3.45,19.07v1.21H5.7V19.07Zm4.49,0v1.21h2.24V19.07Zm4.49,0v1.21h2.24V19.07Z"
                }
              })
            ])
          ]
        ),
        _vm._v("\n    Add to Calendar +\n  ")
      ]
    ),
    _vm._v(" "),
    _vm.isActive
      ? _c("div", { staticClass: "dropdown" }, [_vm._t("default")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }