<template>
  <div>
    <slot
      v-bind:performSearch="performSearch"
      v-bind:updateSearchQuery="updateSearchQuery"
    >

    </slot>
    <div class="autosuggest">
      <div class="l-overlay-container">
        <ul class="search-autosuggest">
          <li
            v-bind:key=index
            v-for="(searchResult, index) in searchResults"
          >
            <a class="link" v-bind:href="searchResult.url">{{ searchResult.title }}{{ searchResult.subtitle && `: ${searchResult.subtitle}` }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import axios from 'axios';

const apiUrl = '/api';

const searchSections = ['events', 'pages'];
const searchPrefix = '';
// The query to search for entries in Craft
const searchQuery = `
  query searchQuery($sections: [String], $needle: String!, $limit: Int)
    {
      entries(section: $sections, search: $needle, limit: $limit) {
        ... on events_event_Entry {
          title
          subtitle
          url
        }
      }
    }
  `;
// Configure the api endpoint
const configureApi = (url, token) => {
  return {
    baseURL: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest'
    }
  };
};
// Execute a GraphQL query by sending an XHR to our api endpoint
const executeQuery = (api, query, variables, callback) => {
    api.post('', {
        query: query,
        variables: variables
    }).then((result) => {
        if (callback) {
            callback(result.data);
        }
    }).catch((error) => {
        console.log(error);
    })
};

export default {
  props: {
    apiToken: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchApi: axios.create(configureApi(apiUrl, this.apiToken)),
      searchQuery: '',
      searchResults: {},
      placeholder: 'Search our archives...'
    }
  },
  methods: {
    // Perform a search
    performSearch() {
      // If they haven't entered anything to search for, return nothing
      if (this.searchQuery === '') {
        this.searchResults = {};
        return;
      }
      // Set the variables we will pass in to our query
      const variables = {
        sections: searchSections,
        needle: searchPrefix + this.searchQuery
      };
      // Execute the query
      executeQuery(this.searchApi, searchQuery, variables, data => {
        this.searchResults = data.data.entries;
      });
    },
    updateSearchQuery: debounce(function(event) {
      this.searchQuery = event.target.value
    }, 500),
    updatePlaceholder: debounce(function(){
      this.placeholder = window.matchMedia('(min-width: 47.9375em)').matches ? 'Search our archives for an event, speaker, topic, etc…' : 'Search our archives...'
    }, 250)
  },
  watch: {
    searchQuery(value) {
      this.performSearch(value);
    },
    placeholder(value) {
      this.$el.querySelector('input').placeholder = value
    }
  },
  created(){
    this.updatePlaceholder()
    window.addEventListener('resize', () => {
      this.updatePlaceholder()
    });
  }
};
</script>
