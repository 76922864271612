var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button-close",
      attrs: { type: "button" },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "svg",
        {
          staticClass: "icon",
          attrs: {
            "data-name": "Layer 1",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 18.52 18.52"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                fill: "none",
                stroke: "#fff",
                "stroke-linecap": "square",
                "stroke-width": "2"
              }
            },
            [
              _c("path", {
                attrs: { d: "M1.41 1.41l15.7 15.7M1.76 16.76l15-15" }
              })
            ]
          )
        ]
      ),
      _vm._v("\n  esc\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }