var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "events-filter" },
    [
      _c(
        "button",
        {
          staticClass: "events-filter-toggler",
          class: _vm.isActive && "-active",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.isActive = !_vm.isActive
            }
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.currentFilter.title) + "\n    "),
          _c(
            "svg",
            {
              staticClass: "icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 10.99 6.91"
              }
            },
            [
              _c("path", {
                attrs: {
                  d: "M.71.71,5.5,5.5,10.29.71",
                  fill: "none",
                  stroke: "currentColor",
                  "stroke-width": "2"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "events-filter" } }, [
        _vm.filters && _vm.baseUrl && _vm.isActive
          ? _c(
              "div",
              { staticClass: "events-filter-dropdown" },
              _vm._l(_vm.filters, function(item) {
                return _c(
                  "a",
                  {
                    key: item.slug,
                    staticClass: "filter",
                    attrs: {
                      href:
                        "" +
                        _vm.baseUrl +
                        (item.slug && item.slug + "/") +
                        _vm.urlSuffix
                    }
                  },
                  [_vm._v(_vm._s(item.title))]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }