var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", null, {
        performSearch: _vm.performSearch,
        updateSearchQuery: _vm.updateSearchQuery
      }),
      _vm._v(" "),
      _c("div", { staticClass: "autosuggest" }, [
        _c("div", { staticClass: "l-overlay-container" }, [
          _c(
            "ul",
            { staticClass: "search-autosuggest" },
            _vm._l(_vm.searchResults, function(searchResult, index) {
              return _c("li", { key: index }, [
                _c(
                  "a",
                  { staticClass: "link", attrs: { href: searchResult.url } },
                  [
                    _vm._v(
                      _vm._s(searchResult.title) +
                        _vm._s(
                          searchResult.subtitle && ": " + searchResult.subtitle
                        )
                    )
                  ]
                )
              ])
            }),
            0
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }