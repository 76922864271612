<template>
  <div class="audio-player">
    <button class="play" @click="play" title="Play/Pause" href="#">
      <span class="play-icon" :class="{ '-playing': playing }"></span>
    </button>
    <transition name="audio-loaded" :duration="800">
      <div class="audio-loaded-content" v-if="loaded">
        <div class="audio-player-progress">
          <div :style="{ width: this.percentComplete + '%' }" class="player-seeker"></div>
          <span class="clickable-area" @click="seek"></span>
        </div>
        <div class="audio-player-time">
          {{ this.currentSeconds | convertTimeHHMMSS }}/{{ this.durationSeconds | convertTimeHHMMSS }}
        </div>
      </div>
    </transition>
    <template v-if="!loaded">
      <p class="placeholder-text">Listen Now</p>
    </template>

    <audio :loop="innerLoop" ref="audiofile" :src="file" preload="none" style="display: none;"></audio>
  </div>
</template>

<script>
export default {
	props: {
		uid: {
			type: String,
			default: null
		},
		file: {
			type: String,
			default: null
    },
		isPlaying: {
			type: Boolean,
			default: false
		},

		loop: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		audio: undefined,
		currentSeconds: 0,
		durationSeconds: 0,
		innerLoop: false,
		loaded: false,
		playing: false,
	}),
	computed: {
		percentComplete() {
			return parseInt(this.currentSeconds / this.durationSeconds * 100);
		},
		muted() {
			return this.volume / 100 === 0;
		}
	},
	filters: {
		convertTimeHHMMSS(val) {
			let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

			return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
		}
	},
	watch: {
		playing(value) {
			if (value) { return this.audio.play(); }
			this.audio.pause();
    },
    isPlaying(value) {
      this.playing = value;
    }
	},
	methods: {
		download() {
			this.stop();
			window.open(this.file, 'download');
		},
		load() {
			if (this.audio.readyState >= 2) {
				this.loaded = true;
				return this.durationSeconds = parseInt(this.audio.duration);
			}

			throw new Error('Failed to load sound file.');
		},
		seek(e) {
			if (!this.playing) {
				return;
      }

			const el = e.target.getBoundingClientRect();
      const seekPos = (e.clientX - el.left) / el.width;

      this.audio.currentTime = parseInt(this.audio.duration * seekPos);
		},
		update(e) {
			this.currentSeconds = parseInt(this.audio.currentTime);
    },
    play() {
      this.$root.togglecurrentAudioPlayer(this.uid);
    }
	},
	created() {
		this.innerLoop = this.loop;
	},
	mounted() {
		this.audio = this.$el.querySelectorAll('audio')[0];
		this.audio.addEventListener('timeupdate', this.update);
		this.audio.addEventListener('loadeddata', this.load);
		this.audio.addEventListener('pause', () => { this.playing = false; });
		this.audio.addEventListener('play', () => { this.playing = true; });
	}
};
</script>

