<template>
  <div class="l-modal">
    <button-close @click="closeModal" />
    <div class="iframe" v-if="modalContent.youtube" v-on-click-outside="closeModal">
      <iframe :src="modalContent.youtube" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="content" :class="modalContent.contentClass" v-on-click-outside="closeModal" v-else>
      <div class="image" v-html="modalContent.image"></div>
      <div class="modal-content" ref="modal-content" :class="{'_unpadding-bottom': modalContent.closeButton }" v-html="modalContent.html"></div>
      <div class="modal-footer" v-if="modalContent.closeButton">
        <button
          @click="closeModal"
          type="button"
          class="button -light-blue -modal-close"
          >
          Close
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonClose from '../../_components/button-close/index.vue'

export default {
  components: {
    ButtonClose
  },
  props: {
    modalContent: {
      type: Object,
      default: null
    }
  },
  methods: {
    closeModal() {
      this.$root.modalContent = null
    }
  },
  mounted(){
    this.$refs['modal-content'].addEventListener('click', (event) => {
      const target = event ? event.target : window.event.srcElement;
      if (target.className === 'button -light-blue -xl') {
        this.closeModal()
      }
    });
  },
  destroyed() {
    this.modalContent.onDestroy && this.modalContent.onDestroy();
  }
}
</script>
