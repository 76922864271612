var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "l-modal" },
    [
      _c("button-close", { on: { click: _vm.closeModal } }),
      _vm._v(" "),
      _vm.modalContent.youtube
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-click-outside",
                  rawName: "v-on-click-outside",
                  value: _vm.closeModal,
                  expression: "closeModal"
                }
              ],
              staticClass: "iframe"
            },
            [
              _c("iframe", {
                attrs: {
                  src: _vm.modalContent.youtube,
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ]
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "on-click-outside",
                  rawName: "v-on-click-outside",
                  value: _vm.closeModal,
                  expression: "closeModal"
                }
              ],
              staticClass: "content",
              class: _vm.modalContent.contentClass
            },
            [
              _c("div", {
                staticClass: "image",
                domProps: { innerHTML: _vm._s(_vm.modalContent.image) }
              }),
              _vm._v(" "),
              _c("div", {
                ref: "modal-content",
                staticClass: "modal-content",
                class: { "_unpadding-bottom": _vm.modalContent.closeButton },
                domProps: { innerHTML: _vm._s(_vm.modalContent.html) }
              }),
              _vm._v(" "),
              _vm.modalContent.closeButton
                ? _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button -light-blue -modal-close",
                        attrs: { type: "button" },
                        on: { click: _vm.closeModal }
                      },
                      [_vm._v("\n        Close\n      ")]
                    )
                  ])
                : _vm._e()
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }