var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-player" },
    [
      _c(
        "button",
        {
          staticClass: "play",
          attrs: { title: "Play/Pause", href: "#" },
          on: { click: _vm.play }
        },
        [
          _c("span", {
            staticClass: "play-icon",
            class: { "-playing": _vm.playing }
          })
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "audio-loaded", duration: 800 } }, [
        _vm.loaded
          ? _c("div", { staticClass: "audio-loaded-content" }, [
              _c("div", { staticClass: "audio-player-progress" }, [
                _c("div", {
                  staticClass: "player-seeker",
                  style: { width: this.percentComplete + "%" }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "clickable-area",
                  on: { click: _vm.seek }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "audio-player-time" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("convertTimeHHMMSS")(this.currentSeconds)) +
                    "/" +
                    _vm._s(_vm._f("convertTimeHHMMSS")(this.durationSeconds)) +
                    "\n      "
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.loaded
        ? [_c("p", { staticClass: "placeholder-text" }, [_vm._v("Listen Now")])]
        : _vm._e(),
      _vm._v(" "),
      _c("audio", {
        ref: "audiofile",
        staticStyle: { display: "none" },
        attrs: { loop: _vm.innerLoop, src: _vm.file, preload: "none" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }