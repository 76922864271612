import { tns } from "tiny-slider/src/tiny-slider.module"

const sliders = document.querySelectorAll('.js-quotes-slider');

Array.from(sliders).forEach(function(el){
  tns({
    container: el,
    // mode: 'gallery',
    items: 1,
    controlsPosition: 'bottom',
    navPosition: 'bottom',
  });
})
