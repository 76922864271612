import Vue from 'vue'

import Cookies from 'js-cookie'

import { directive as onClickOutside } from 'vue-on-click-outside'
Vue.directive('on-click-outside', onClickOutside)

import NotificationBar from '../templates/_components/notification-bar/index.vue'
import AudioPlayer from '../templates/_components/audio-player/index.vue'
import EventsFilter from '../templates/_components/events-filter/index.vue'
import SocialShare from '../templates/_components/social-share/index.vue'
import CalendarAdd from '../templates/_components/calendar-add/index.vue'
import SearchAutosuggest from '../templates/_components/search-form/index.vue'
import SpeakersNavLettersNav from '../templates/_components/speakers-nav/index.vue'
import NewsletterForm from '../templates/_components/newsletter-form/index.vue'
import VueModal from '../templates/_layouts/modal/index.vue'

const popup = document.querySelector('#popup-loader');

if(document.getElementById("vue")){
  const vue = new Vue({
    el: '#vue',
    components: {
      NotificationBar,
      AudioPlayer,
      EventsFilter,
      SocialShare,
      CalendarAdd,
      SearchAutosuggest,
      SpeakersNavLettersNav,
      NewsletterForm,
      VueModal
    },
    data() {
      return {
        showOverlayContent: false,
        overlayComponent: null,
        navLevel: null,
        activeTab: null,
        currentAudioPlayer: null,
        showOverlayContentChildren: false,
        modalContent: null,
        signedUp: false,
        mobileMenuActive: true,
        scrollPos: 0,
      }
    },
    methods: {
      toggleOverlayComponent(component) {
        this.overlayComponent = (this.overlayComponent !== component) ? component : null;
      },
      toggleNavLevel(level) {
        this.navLevel = (this.navLevel !== level) ? level : null;
      },
      showTab(tab) {
        this.overlayComponent = 'tabs'
        this.activeTab = tab;
      },
      togglecurrentAudioPlayer(uid) {
        this.currentAudioPlayer = (this.currentAudioPlayer !== uid) ? uid : null;
      },
      showPopup(popupLoader) {
        const popupOpts = popupLoader.dataset;
        this.modalContent = {
          image: popupOpts.image,
          html: popupLoader.innerHTML,
          onDestroy: () => {
            Cookies.set(popupOpts.cookie, true, { expires: parseInt(popupOpts.expires) })
          }
        }
        popupLoader.parentNode.removeChild(popupLoader);
      },
      handleKeyup(event) {
        if (event.keyCode === 27) {
          const closeButton = document.querySelector('a.button-close')
          if (closeButton) {
            window.location.replace(closeButton.href)
          } else {
            this.toggleOverlayComponent(null)
            this.modalContent = null
          }
        }
      },
      onScroll(evt) {
        this.mobileMenuActive = this.scrollPos > window.scrollY || window.scrollY <= 0 || this.overlayComponent
        this.scrollPos = window.scrollY;
      }
    },
    computed: {
      overlayAnimationDuration() {
        return this.overlayComponent === 'tabs' ? 700 :
          (this.overlayComponent === 'mobile-nav' ? 1100 : 500)
      }
    },
    created() {
      window.addEventListener('scroll', this.onScroll);
      document.addEventListener('keyup', this.handleKeyup);

      popup && this.showPopup(popup)

    }
  });
}
