<template>
  <transition name="notification-bar">
    <div :class="{'-active': isActive}" v-if="isActive">
      <slot></slot>
      <button class="close" id="js-notification-close" @click="closeNotification">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.52 18.52" class="icon">
          <g fill="none" stroke="#fff" stroke-linecap="square" stroke-width="2">
            <path d="M1.41 1.41l15.7 15.7M1.76 16.76l15-15"></path>
          </g>
        </svg>
      </button>
    </div>
  </transition>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data: () => ({
    isActive: true
  }),
  methods: {
    closeNotification() {
      this.isActive = false;
      Cookies.set('notification-hidden', true)
    }
  }
}
</script>
