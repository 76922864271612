<template>
  <div>
    <slot :onSubmit="onSubmit" :errors="errors"></slot>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data: () => ({
    errors: {},
    formErrors: []
  }),
  methods: {
    onSubmit(e) {
      var self = this;
      grecaptcha.ready(function() {
        grecaptcha
          .execute("6Lfgf8oZAAAAAGAgfMkL_SdTUS9hGB9qFcGZF7yR", {
            action: "submit"
          })
          .then(function(token) {
            self.ajaxifyForm(e.target, token);
          });
      });
    },
    ajaxifyForm(form, token) {
      const self = this;

      const data = new FormData(form);
      data.set("g-recaptcha-response", token);

      const method = form.getAttribute("method");
      const action = form.getAttribute("action");

      const request = new XMLHttpRequest();
      request.open(method, action ? action : window.location.href, true);
      request.setRequestHeader("Cache-Control", "no-cache");

      // Set the Craft specific AJAX headers
      request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

      request.onload = function() {
        if (request.status === 200) {
          const response = JSON.parse(request.response);

          if (response.success && response.finished) {
            form.reset();
            self.onSuccess();
          } else if (response.errors || response.formErrors) {
            self.onFail(response);
          }

          // Update the Honeypot field if using JS enhancement
          if (response.honeypot) {
            var honeypotInput = form.querySelector("input[name^=faith]");
            honeypotInput.setAttribute("name", response.honeypot.name);
            honeypotInput.setAttribute("id", response.honeypot.name);
            honeypotInput.value = response.honeypot.hash;
          }
        } else {
          console.error(request);
        }
      };
      request.send(data);
    },
    onSuccess() {
      this.$root.modalContent = {
        html:
          '<p class="title">Thank You</p><p class="text">Thanks for subscribing to our newsletter.\
          You should receive a confirmation email soon.</p>',
        contentClass: "-sm",
        closeButton: true
      };
      this.$root.signedUp = true;
      Cookies.set("signed-up", true, { expires: 365 * 10 });
    },
    onFail(response) {
      var self = this;
      response.errors && (self.errors = response.errors);
      response.formErrors && self.formErrors.push(response.error);
    }
  }
};
</script>
