var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "speakers-nav-letters-nav" },
    _vm._l(_vm.letters, function(letter) {
      return _c("li", { key: letter }, [
        _c(
          "button",
          {
            staticClass: "link",
            on: {
              click: function($event) {
                return _vm.handleClick(letter)
              }
            }
          },
          [_vm._v(_vm._s(letter))]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }