<template>
  <ul class="speakers-nav-letters-nav">
    <li v-for="letter in letters" :key="letter">
      <button
        @click="handleClick(letter)"
        class="link"
        >{{letter}}</button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    letters: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleClick(letter){
      document.getElementById('speakers-nav-section-' + letter).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}
</script>
