<template>
  <div class="calendar-add">
    <button class="dropdown-toggler" @click="isActive = !isActive">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.61 23.73"><g><path d="M4,0V1.12H0V23.73H22.61V1.12h-4V0H17.38V1.12H5.23V0ZM1.22,2.33H4V3.45H5.23V2.33H17.38V3.45H18.6V2.33h2.8V5.61H1.21Zm0,4.49H21.4v15.7H1.21Zm6.72,3.27v1.22h2.25V10.09Zm4.49,0v1.22h2.24V10.09Zm4.49,0v1.22h2.24V10.09ZM3.45,14.58v1.21H5.7V14.58Zm4.49,0v1.21h2.24V14.58Zm4.49,0v1.21h2.24V14.58Zm4.48,0v1.21h2.25V14.58ZM3.45,19.07v1.21H5.7V19.07Zm4.49,0v1.21h2.24V19.07Zm4.49,0v1.21h2.24V19.07Z"/></g></svg>
      Add to Calendar +
    </button>
    <div class="dropdown" v-if="isActive">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isActive: false
  })
}
</script>
